.home-container{
    height: 98vh;
    width: 98vw;

    background-image: url("../assets/Images/login_background_graphics.png");
    background-size: cover;
    background-position: center; 
    background-repeat: no-repeat; 
    overflow-y: hidden;
    padding: 1vh 1vw;
}

.app-name{
    width: 15%;
    height: 15%;
    margin: 1vh 0;
}

.app-thanks{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 60vh;
    font-size: 4vw;
    font-weight: 600;
}

.app-header{
    display: flex;
    justify-content: space-between;
}

.app-signup{
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    height: 60vh;
    font-size: 2vw;
    font-weight: 600;
    padding: 0 2vw;
}

.app-logo{
    width: 10%;
    height: 10%;
    margin: 1vh 0;
}