.signup-container, .login-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    height: 100vh;
    width: 100vw;

    background-image: url("../assets/Images/login_background_graphics.png");
    background-size: cover;
    background-position: center; 
    background-repeat: no-repeat; 
}

.signup-form, .login-form{
    background-color: white;
    padding: 3vh 3vw;

    border-radius: 1vh;
}

.title-box{
    display: flex;
    align-items: center;
    justify-content: left;

    gap: 1vw;

    margin-bottom: 4vh;
}

.title-box img{
    width: 4vw;
}
